import React, { useState } from "react";
import "./login.css";
import { DocketAPIError, getAPIClient } from "../../apiClient";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { errorLog } from "../../utils/logger";
import moment from "moment/moment";
import db, { Key } from "../../database";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { UserAccount } from "../../models/Interfaces";
import { userAtom } from "../../jotai/atoms";
import { useAtom } from "jotai";

export const EnterPhonePinSchema = yup.object().shape({
  pin: yup.string().required().min(6).max(6),
});

export function EnterPhonePin() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const [showErrorModal, setShowErrorModal] = useState<string | boolean>();

  const [dob, setDob] = useState(moment().subtract(18, "years").toDate());
  const [sendFromSettings, setSendFromSettings] = useState(false);

  const [currentUser, setCurrentUser] = useAtom(userAtom);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
    getValues,
  } = useForm({
    resolver: yupResolver(EnterPhonePinSchema),
  });

  const searchFormTranslations = {
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
  };

  const onSendFromSettings = async (data: any) => {
    setSendFromSettings(true);
    await onSubmit(data);
  };

  const onSubmit = async (data: any) => {
    if (!data || !data.pin) {
      return;
    }

    try {
      await getAPIClient().verifyUserAccountPhone({ code: data.pin });
      // We re-retrieve the user here since the verified phone number may now be set
      const user = await getAPIClient().getUser();
      if (!user?.verified_phone_number) {
        throw new DocketAPIError(t("onboarding.verify-enter-pin-error"));
      }

      await setCurrentUser({ ...user, tokens: currentUser?.tokens });
      if (!sendFromSettings) {
        navigate("/home/search");
      }
    } catch (e: unknown) {
      errorLog(e as Error);
      if (e instanceof DocketAPIError) {
        setShowErrorModal(e.message);
      }
    }
  };

  const getEnterPinLayout = () => {
    // TODO: Pull out the core bit of this into a separate component.
    if (pathname === "/signup/enterpin") {
      // sign up flow
      return (
        <>
          {showErrorModal && (
            <ErrorModal onCloseModal={() => setShowErrorModal(false)}>{showErrorModal}</ErrorModal>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-container">
              <p className="label">{t("settings.enter_pin")}:</p>
              <input
                className="input has-text-dark"
                type="text"
                placeholder="123456"
                {...register("pin")}
              />
            </div>
            <button
              className="mt-4 button docket-button is-fullwidth"
              type="submit"
              disabled={!isValid}
            >
              {t("generic.submit").toUpperCase()}
            </button>
            <div className="is-fullWidth has-text-centered mt-6">
              <a
                className="mt-4 docket-link has-text-link nounderline"
                onClick={() => navigate("/home/search")}
              >
                {t("generic.skip").toUpperCase()}
              </a>
            </div>
          </form>
        </>
      );
    } else if (pathname === "/home/settings") {
      // settings update phone
      return (
        <>
          <form onSubmit={handleSubmit(onSendFromSettings)}>
            <div className="input-container">
              <label className="label form-input-label">{t("settings.enter_pin")}:</label>
              <input
                className="input has-text-dark"
                type="text"
                placeholder="123456"
                {...register("pin")}
              />
            </div>
            <button
              className="mt-4 button is-fullwidth docket-button"
              type="submit"
              disabled={!isValid || sendFromSettings}
            >
              {t("generic.submit").toUpperCase()}
            </button>
          </form>
        </>
      );
    } else {
      throw new Error("Sorry, this is not supported");
    }
  };

  return <>{getEnterPinLayout()}</>;
}

export default EnterPhonePin;
