import React, { ReactNode, createContext, useEffect } from "react";
import { brandingConfig, BrandingConfig } from "./branding.config";
import { WHITELABEL_KEY, USE_WHITELABEL_BRANDING } from "../globals";
import { generateBrandingStyles } from "./brandingStyles";

export const BrandingContext = createContext<BrandingConfig>({} as BrandingConfig);

interface BrandingProviderProps {
  children: ReactNode;
}

const BrandingProvider: React.FC<BrandingProviderProps> = ({ children }) => {
  const branding: BrandingConfig = USE_WHITELABEL_BRANDING
    ? brandingConfig[WHITELABEL_KEY] || brandingConfig["default"]
    : brandingConfig["default"];

  useEffect(() => {
    // Apply branding styles
    const style = document.createElement("style");
    style.textContent = generateBrandingStyles(branding);
    document.head.appendChild(style);

    // Update theme-color meta tag
    let themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (!themeColorMeta) {
      themeColorMeta = document.createElement("meta");
      themeColorMeta.setAttribute("name", "theme-color");
      document.head.appendChild(themeColorMeta);
    }
    themeColorMeta.setAttribute("content", branding.siteBackground);

    return () => {
      document.head.removeChild(style);
    };
  }, [branding]);

  return <BrandingContext.Provider value={branding}>{children}</BrandingContext.Provider>;
};

export default BrandingProvider;
