import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ContactMethodEnum, ScreeningEvent } from "../models/Interfaces";

interface EventCardProps {
  index: number;
  event: ScreeningEvent;
}

export default function ScreeningEventCard({ event, index }: EventCardProps) {
  const { t } = useTranslation();

  const readableDate = (date: string) => {
    return moment.parseZone(date).format("L");
  };

  const hasOrderingFacilityData =
    event?.orderingFacility &&
    (event?.orderingFacility.names.length > 0 || event.orderingFacility.contactInfo.length > 0);

  return (
    <div className="box event-card">
      <div className=" columns is-gapless is-vcentered ">
        <div className="column">
          {event.testDate && (
            <div
              role="paragraph"
              className="is-size-4 has-text-weight-bold is-family-primary has-text-dark"
              data-testid="event-card-title"
            >
              {readableDate(event.testDate)}
            </div>
          )}
          {event.type && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-specimen"
            >
              <b>{t("screening.records_event_specimen")}</b>
              {t("screening.results_lead", { context: event.type })}
            </div>
          )}
          {event.measurement && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-test-value"
            >
              <b>{t("screening.records_event_test_value")}</b> {event.measurement}{" "}
              {event.units ? event.units : ""}
            </div>
          )}
          {event?.laboratory && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-reference-value"
            >
              <b>{t("screening.records_event_laboratory")}</b> {event.laboratory}
            </div>
          )}
          {hasOrderingFacilityData && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark"
              data-testid="event-card-ordering-facility"
            >
              <b>{t("screening.records_event_ordering_facility")}</b>
              {event.orderingFacility.names.join(", ")}
              {event.orderingFacility.contactInfo.map((contact, index) => (
                <>
                  {contact.method !== ContactMethodEnum.Unknown && (
                    <div key={contact.value}>
                      <b>
                        {t(`screening.records_event_contact`, {
                          context: contact.method.toLowerCase(),
                        })}
                      </b>{" "}
                      {contact.value}
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
