import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import db, { Key } from "../database";
import { STAY_LOGGED_IN_PW } from "../globals";
import { getAPIClient } from "../apiClient";
import { UserAccount } from "../models/Interfaces";
import { getAuth } from "firebase/auth";
import logout from "../utils/logout";
import { fetchRecordsUntilUpdated } from "../utils/polling";

interface RequiresAuthProps {
  children: JSX.Element;
}

export default function RequiresAuth({ children }: RequiresAuthProps) {
  const [isAuthed, setIsAuthed] = useState<Boolean | null>(null);
  const navigate = useNavigate();

  async function signOut() {
    await logout(getAPIClient(), getAuth());
    navigate("/");
  }

  /**
   * This side effect is responsible for the initial loading of all the user's
   * records when this component mounts - which will only happen when the user
   * logs in or refreshes the app.
   */
  useEffect(() => {
    if (isAuthed) {
      fetchRecordsUntilUpdated();
    }
  }, [isAuthed]);

  useEffect(() => {
    const checkDB = async () => {
      let authed = false;
      try {
        authed = await db().unlock(STAY_LOGGED_IN_PW);

        const user = await db().getItem<UserAccount>(Key.UserAccount);
        /**
         * We should also consider ourselves not authenticated if the user
         * account that we have saved is missing the new access/refresh token
         * pair. This will correctly log the user out in case they had logged in
         * prior to our rollout of the new tokens (with the setting checked to
         * stay logged in). If we consider them logged in, we get into a whole mess
         * of other problems when trying to make requests without the new tokens.
         */
        if (!user || !user.tokens) {
          authed = false;
        }
      } catch (e: unknown) {
        authed = false;
      } finally {
        setIsAuthed(authed);
        if (!authed) {
          await signOut();
        }
      }
    };

    if (isAuthed === null) {
      checkDB();
    }
  }, []);

  if (isAuthed) {
    return children;
  }
  return null;
}
