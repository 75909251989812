import React from "react";
import "./iconButton.css";

interface IconButtonProps {
  icon: string;
  onClick: () => void;
  alt: string;
  dataTestId?: string;
}

export function IconButton({ icon, onClick, alt, dataTestId }: IconButtonProps) {
  return (
    <button
      className={`icon-button ${icon}`}
      aria-label={alt}
      onClick={onClick}
      data-testid={dataTestId}
    ></button>
  );
}
