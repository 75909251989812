import { ImmunizationPatient, ImmunizationRecord, ScreeningRecord } from "../models/Interfaces";
import React, { useState } from "react";
import { infoLog } from "../utils/logger";
import { useTranslation } from "react-i18next";
import "./patientCard.css";
import DisclaimerCard from "./Disclaimers/DisclaimerCard";
import RecordCard from "./RecordCard";
import { useNavigate } from "react-router";
import { IconButton } from "./IconButton/IconButton";
import { Spinner } from "./spinner/Spinner";
import ScreeningRecordCard from "./ScreeningRecordCard";
import { patientRecordsAtom, patientScreeningsAtom } from "../jotai/atoms";
import { useAtom } from "jotai";

interface PatientCardProps {
  index: number;
  patient: ImmunizationPatient;
  screening?: boolean;
}

export default function PatientCard({ patient, index, screening }: PatientCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [records] = useAtom(patientRecordsAtom);
  const [screenings] = useAtom(patientScreeningsAtom);

  const patientRecords = records.filter((x) => x.searchUid === patient.searchUid);
  const patientScreenings = screenings.filter((x) => x.searchUid === patient.searchUid);

  const selectItemHandler = async (record: ImmunizationRecord) => {
    infoLog(`searchObj ${JSON.stringify(record)}`);
    //await dispatch(setSearch(searchObj));
  };

  const goToReports = () => {
    navigate("reports", { state: { patient: patient } });
  };

  const ExpandedIcon = () => (
    <div onClick={() => setExpanded((currentExpanded) => !currentExpanded)} role="button">
      <i className="fa-solid fa-angle-down"></i>
    </div>
  );

  const CollapsedIcon = () => (
    <div onClick={() => setExpanded((currentExpanded) => !currentExpanded)} role="button">
      <i className="fa-solid fa-angle-up"></i>
    </div>
  );

  return (
    <div className="patient-card">
      <div className="level card-header pt-2 pb-2">
        <div className="level-left ml-4">
          <div
            role="paragraph"
            className="title is-5 has-text-white patient-name"
            data-testid="search-card-name"
          >
            {`${patient.firstName}  ${patient.lastName}`}
          </div>
        </div>

        <div className="level-right is-flex is-flex-direction-row is-align-items-center mt-0 patient-name-controls">
          {!screening && (
            <>
              <div className="level-item has-text-centered mr-4 mb-0">
                <IconButton onClick={() => goToReports()} icon="pdf" alt={"pdf"} />
              </div>
              <div className="level-item has-text-centered mr-4 mb-0">|</div>
            </>
          )}
          <div className="level-item mr-5 is-size-4 mt-1 mb-0">
            {expanded === true ? <ExpandedIcon /> : <CollapsedIcon />}
          </div>
        </div>
      </div>
      <li>
        {expanded && (
          <div className="columns is-multiline is-align-items-stretch">
            {screening ? (
              <>
                <div
                  className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column"
                  key={index}
                >
                  <DisclaimerCard index={index} provider={patient.izProviderKey} screening />
                </div>
                {patientScreenings?.map((screeningRecord, index) => (
                  <div
                    className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column"
                    key={index}
                  >
                    <ScreeningRecordCard key={index} patient={patient} record={screeningRecord} />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column">
                  <DisclaimerCard index={index} provider={patient.izProviderKey} />
                </div>
                {patientRecords.map((record: ImmunizationRecord, index: number) => (
                  <div
                    className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column"
                    key={index}
                  >
                    <RecordCard
                      index={index}
                      record={record}
                      patient={patient}
                      onSelect={() => {
                        selectItemHandler(record);
                      }}
                    />
                  </div>
                ))}
                {(!patientRecords || patientRecords.length === 0 || records?.length < 2) && (
                  <div className="ml-2 mt-2 has-text-dark is-flex is-align-items-center">
                    {" "}
                    <Spinner />{" "}
                    <b className="pl-4 is-size-5">{t("immunizations.search_records_loading")}</b>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </li>
    </div>
  );
}
