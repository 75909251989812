import Bugsnag from "@bugsnag/js";
import React from "react";
import { useRouteError } from "react-router-dom";
import { errorLog } from "../utils/logger";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();
  const error: any = useRouteError();
  errorLog(error as Error);
  Bugsnag.notify(error);

  return (
    <div className="is-flex is-justify-content-center is-align-items-center p-6 is-flex-direction-column">
      <h1 className="is-size-3 mt-5">{t("system.not_found")}</h1>
      <a className="is-text-white mt-4" href="/">
        {t("generic.try")} app.dockethealth.com?
      </a>
      <img className="mt-4" src="/assets/images/error.png" />
    </div>
  );
};
