import { TFunction } from "i18next";
import { IzProvider, IzProviderStatusImageSettings } from "./IzProviders";

export class DefaultIzProvider implements IzProvider {
  governmentDepartmentName: string;
  recordDisclaimerTitle: string;
  recordDisclaimerText: string;
  screeningResultLink: string;
  searchLegalText: string;
  iisAbbrev: string;
  iisName: string;
  jurisdictionAbbrev: string;
  jurisdictionName: string;
  scenarioBImagePath: string;
  statusImages: IzProviderStatusImageSettings;
  reportTitles: string[];
  izDestinationId: string;
  t: TFunction;

  static readonly STATES_TO_NAMES = new Map<string, string>([
    ["al", "Alabama"],
    ["ak", "Alaska"],
    ["az", "Arizona"],
    ["ar", "Arkansas"],
    ["ca", "California"],
    ["co", "Colorado"],
    ["ct", "Connecticut"],
    ["de", "Delaware"],
    ["dc", "District of Columbia"],
    ["fl", "Florida"],
    ["ga", "Georgia"],
    ["hi", "Hawaii"],
    ["id", "Idaho"],
    ["il", "Illinois"],
    ["in", "Indiana"],
    ["ia", "Iowa"],
    ["ks", "Kansas"],
    ["ky", "Kentucky"],
    ["la", "Louisiana"],
    ["me", "Maine"],
    ["md", "Maryland"],
    ["ma", "Massachusetts"],
    ["mi", "Michigan"],
    ["mn", "Minnesota"],
    ["ms", "Mississippi"],
    ["mo", "Missouri"],
    ["mt", "Montana"],
    ["ne", "Nebraska"],
    ["nv", "Nevada"],
    ["nh", "New Hampshire"],
    ["nj", "New Jersey"],
    ["nm", "New Mexico"],
    ["ny", "New York"],
    ["nc", "North Carolina"],
    ["nd", "North Dakota"],
    ["oh", "Ohio"],
    ["ok", "Oklahoma"],
    ["or", "Oregon"],
    ["pa", "Pennsylvania"],
    ["pr", "Puerto Rico"],
    ["ri", "Rhode Island"],
    ["sc", "South Carolina"],
    ["sd", "South Dakota"],
    ["tn", "Tennessee"],
    ["tx", "Texas"],
    ["ut", "Utah"],
    ["vt", "Vermont"],
    ["va", "Virginia"],
    ["wa", "Washington"],
    ["wv", "West Virginia"],
    ["wi", "Wisconsin"],
    ["wy", "Wyoming"],
  ]);

  constructor(izDestinationId: string, t: TFunction) {
    this.izDestinationId = izDestinationId;
    this.t = t;

    const lowerDestId = this.izDestinationId.toLocaleLowerCase();

    this.jurisdictionName = DefaultIzProvider.STATES_TO_NAMES.get(lowerDestId) || "";
    this.jurisdictionAbbrev = izDestinationId.toUpperCase();

    this.iisAbbrev = t("immunizations.disclaimer_iis", { context: lowerDestId });
    this.iisName = `${this.jurisdictionName} Immunization Information System`;

    this.governmentDepartmentName = t("discover.page", {
      context: lowerDestId,
      jurisdictionName: this.jurisdictionName,
    });
    this.recordDisclaimerTitle = t("immunizations.disclaimer_card_title", { context: lowerDestId });
    this.recordDisclaimerText = t("immunizations.disclaimer_body", { context: lowerDestId });
    this.searchLegalText = "";

    this.screeningResultLink = "";

    // Normally we use the image for the state.
    this.scenarioBImagePath = "/assets/images/Imm_Nurse.png";

    // TODO: i18n of images
    this.statusImages = {
      redOnDue: false,
      useNoRecordImage: false,
      overdueImagePath: "/assets/images/overdueStatus.png",
      dueNowImagePath: "/assets/images/dueNowStatus.png",
      currentImagePath: "/assets/images/currentStatus.png",
      completeImagePath: "/assets/images/completeStatus.png",
      immuneImagePath: "/assets/images/immuneStatus.png",
      recordImagePath: "/assets/images/record.png",
      noRecordImagePath: "/assets/images/noRecordNJ.png",
      processingImagePath: "/assets/images/processing.png",
      overdueLabel: "overdue",
      dueNowLabel: "due_now",
      currentLabel: "current",
      completeLabel: "complete",
      immuneLabel: "immune",
      noRecordLabel: "no_record",
      processingLabel: "processing",
      recordLabel: "record",
    };

    this.reportTitles = [t("immunizations.records_history_report")];
  }
}

export default DefaultIzProvider;
