import React from "react";
import { useRouteError } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import { errorLog } from "./utils/logger";
import { useTranslation } from "react-i18next";

export function ErrorPage() {
  const { t } = useTranslation();
  const error: any = useRouteError();
  errorLog(error as Error);
  Bugsnag.notify(error);

  return (
    <div id="error-page" data-testid="static-error-page">
      <h1>{t("system.unexpected_error_header")}</h1>
      <p>{t("system.unexpected_error")}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

export default ErrorPage;
