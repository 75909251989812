import React from "react";
import { useTranslation } from "react-i18next";

const DefferedAction = (): JSX.Element => {
  const { t } = useTranslation();
  const handleSubmit = (): void => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        if (registration.waiting) {
          registration.waiting.postMessage("SKIP_WAITING");
          return;
        }
      }
    });
  };

  const handleCancel = (): void => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        if (registration.waiting) {
          registration.waiting.postMessage("NO_SKIP_WAITING");
        }
      }
    });
  };

  return (
    <div className="sw_popup" id="sw-toast">
      {t("system.update_available")}
      <div>
        <button onClick={handleSubmit}>{t("system.update_now")}</button>
        <button onClick={handleCancel}>{t("system.skip_update")}</button>
      </div>
    </div>
  );
};

export default DefferedAction;
