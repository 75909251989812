import { AsyncStorage } from "jotai/vanilla/utils/atomWithStorage";
import db, { NoEncryptionKeyError } from "../database";
import { errorLog, infoLog, warnLog } from "../utils/logger";
import localforage from "localforage";

export class EncryptedAsyncStorageDBAdapter<T> implements AsyncStorage<T> {
  getItem(key: string, initialValue: T): PromiseLike<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await db().getItem<T>(key);
        // Nulls are not allowed in our underlying database, so transform them into their initial value here.
        if (res === null) {
          resolve(initialValue);
          return;
        }
        resolve(res);
      } catch (err: any) {
        if (err instanceof NoEncryptionKeyError) {
          // Swallow - this is not that unusual. We always try to read the user account when we first visit the page.
          warnLog(err.message);
          return;
        }
        errorLog(err);
        reject(err);
      }
    });
  }

  setItem(key: string, newValue: T): PromiseLike<void> {
    return new Promise(async (resolve) => {
      await db().setItem(key, newValue);
      resolve();
    });
  }

  removeItem(key: string): PromiseLike<void> {
    return db().removeItem(key);
  }
}

export class UnencryptedAsyncStorageDBAdapter<T> implements AsyncStorage<T> {
  store: LocalForage;
  constructor() {
    this.store = localforage.createInstance({ name: "UnencryptedPrefs" });
  }

  getItem(key: string, initialValue: T): PromiseLike<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.store.getItem<T>(key);
        // Nulls are not allowed in our underlying database, so transform them into their initial value here.
        if (res === null) {
          resolve(initialValue);
          return;
        }
        resolve(res);
      } catch (err: any) {
        errorLog(err);
        reject(err);
      }
    });
  }

  setItem(key: string, newValue: T): PromiseLike<void> {
    return new Promise(async (resolve) => {
      await this.store.setItem(key, newValue);
      resolve();
    });
  }

  removeItem(key: string): PromiseLike<void> {
    return this.store.removeItem(key);
  }
}
