import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PatientCard from "../../components/PatientCard";
import { useNavigate } from "react-router-dom";
import "./records.css";
import { Spinner } from "../../components/spinner/Spinner";
import { IconButton } from "../../components/IconButton/IconButton";
import { getAPIClient } from "../../apiClient";
import { API_DOMAIN, PROTOCOL } from "../../globals";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { izSearchesAtom, patientsAtom, refreshingSearchesOrRecordsAtom } from "../../jotai/atoms";
import { useAtom } from "jotai";
import moment from "moment";
import { fetchRecordsUntilUpdated } from "../../utils/polling";

export function Records() {
  const [allPatients] = useAtom(patientsAtom);
  const [allSearches] = useAtom(izSearchesAtom);
  const [serverRefreshing] = useAtom(refreshingSearchesOrRecordsAtom);

  const [refreshing, setRefreshing] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<string | boolean>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    //infoLog(`hash: ${hash} ${window.location.hash}`);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  useEffect(() => {
    if (!serverRefreshing) {
      setRefreshing(false);
    }
  }, [serverRefreshing]);

  const refreshRecords = async () => {
    setRefreshing(true);
    await getAPIClient().refreshRecords();
    await fetchRecordsUntilUpdated();
  };

  const noPatients = () => {
    return (
      <div
        className="is-flex pr-4 pl-4 is-flex-direction-column is-align-items-center is-halfheight hero is-justify-content-center"
        data-testid="no-patients"
      >
        <h2 className="is-size-5 has-text-weight-bold has-text-black has-text-centered">
          {t("immunizations.search_records_message")}
        </h2>
        <br />
        <br />
        <button className="button docket-button pl-4 pr-4" onClick={() => navigate("/home/search")}>
          {t("immunizations.immunizations_add_records_button")}
        </button>
      </div>
    );
  };

  const openPdf = async () => {
    window.open(`${PROTOCOL}://${API_DOMAIN}/Docket_Immunizations_FAQs_Overview.pdf`, "_blank");
  };

  const withPatients = () => {
    const lastUpdatedTime: Date | null = allSearches.reduce<Date | null>(
      (maxUpdatedDate, curSearch) => {
        const currentUpdatedDate = new Date(curSearch.dateUpdated);

        if (isNaN(currentUpdatedDate.getTime())) {
          return maxUpdatedDate;
        }

        return maxUpdatedDate === null || maxUpdatedDate < currentUpdatedDate
          ? currentUpdatedDate
          : maxUpdatedDate;
      },
      null
    );

    return (
      <div className="records-container">
        <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between is-flex-wrap">
          <div className="level-left">
            <p className="has-text-dark">
              <h4 className="has-text-dark title is-size-4 mb-2">
                {t("immunizations.records_header")}
              </h4>
              <p className="has-text-dark is-size-6">{t("immunizations.records_subtitle")}</p>
              {lastUpdatedTime && (
                <p className="has-text-dark is-size-6">
                  <b>{t("immunizations.updated")}</b>
                  {moment(lastUpdatedTime).format("L")}
                </p>
              )}
            </p>
          </div>
          <div className="level-right mt-0">
            <div className="level-item has-text-centered">
              {refreshing ? (
                <Spinner />
              ) : (
                <IconButton
                  icon="refresh"
                  onClick={() => refreshRecords()}
                  alt={"refresh results"}
                />
              )}
            </div>
            <IconButton icon="faq" onClick={() => openPdf()} alt={"FAQ"} />
          </div>
        </div>
        <div className="separater"></div>
        <ul className="patient-list">
          {allPatients.map((patient, index) => (
            <li id={index.toString()} key={index}>
              <PatientCard patient={patient} index={index} />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="columns is-0">
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>{showErrorModal}</ErrorModal>
      )}
      <div className="column is-gapless">
        {allPatients && allPatients.length > 0 ? withPatients() : noPatients()}
      </div>
    </div>
  );
}

export default Records;
