import { getI18n } from "react-i18next";
import { infoLog } from "./logger";

export enum Reports {
  HistoryReport = "history_report",
  SchoolReport = "school_report",
  ForecastReport = "forecast_report",
  PersonalReport = "personal_report",
}

// TODO: Figure out how to move this into IzProvider
export const getReportForState = (providerKey: string, indx: number): string => {
  const pk = providerKey.toLowerCase();
  if (pk === "nj") {
    if (indx === 0) {
      return Reports.HistoryReport;
    }
    return Reports.ForecastReport;
  }

  if (pk === "ut") {
    switch (indx) {
      case 0:
        return Reports.SchoolReport;
      case 1:
        return Reports.ForecastReport;
      case 2:
        return Reports.PersonalReport;
      case 3:
        return Reports.HistoryReport;
      default:
        return Reports.HistoryReport;
    }
  }

  return Reports.HistoryReport;
};

export const getProviderName = (providerKey: string) => {
  switch (providerKey.toLowerCase()) {
    case "wa":
      return "";
    case "wy":
      return "";
    case "ut":
      return "Utah ";
    case "mn":
      return "Minnesota ";
    case "me":
      return "Maine ";
    case "nj":
      return "New Jersey ";
    case "id":
      return "";
    case "ak":
      return "";
    default:
      break;
  }
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
