import { TFunction } from "i18next";
import DefaultIzProvider from "./DefaultIzProvider";

export class Minnesota extends DefaultIzProvider {
  constructor(izDestinationId: string, t: TFunction) {
    super(izDestinationId, t);
    this.scenarioBImagePath = "/assets/images/large/Docket_Minnesota_scb_f.png";
    this.reportTitles = [t("immunizations.records_history_report_mn")];
  }
}
