import React from "react";
import { immunizationsNJPrivacyPdfURL } from "../utils/data";
import { TFunction } from "i18next";

interface TextProps {
  text: string;
  url?: string;
}

interface LinkProps {
  texts: TextProps[];
}

export const paragraphWithLinks = ({ texts }: LinkProps) => {
  return (
    <div>
      {texts.map((text: TextProps, index: number) => {
        return (
          <p key={index} role="paragraph">
            {!text.url && <p>{text.text}</p>}
            {text.url && <a href={text.url}>{text.text}</a>}
          </p>
        );
      })}
    </div>
  );
};

export const utSearchFooter = (t: TFunction) => {
  return <div>{"Utah: " + t("immunizations.search_records_legal", { context: "ut" })}</div>;
};

export const njSearchFooter = (t: TFunction) => {
  return (
    <div>
      {"New Jersey: " + t("immunizations.search_records_legal", { context: "nj" })}
      <a href={immunizationsNJPrivacyPdfURL} target="_blank">
        <span className="is-underlined">{"New Jersey's Privacy Notice."}</span>
      </a>
    </div>
  );
};

export const mnSearchFooter = (t: TFunction) => {
  return (
    <div>
      {"Minnesota: " + t("immunizations.search_records_legal", { context: "mn" })}
      <a href={"https://www.health.state.mn.us/people/immunize/miic/records.html"} target="_blank">
        {" "}
        <span className="is-underlined">{"Minnesota Department of Health"}</span>
      </a>
    </div>
  );
};

export const idSearchFooter = (t: TFunction) => {
  return (
    <div>
      {"Idaho: " + t("immunizations.search_records_legal", { context: "id" })}
      <br />
      <a
        href={"https://healthandwelfare.idaho.gov/news-notices/public-records-requests"}
        target="_blank"
      >
        <span className="is-underlined">
          {t("immunizations.search_records_legal", { context: "id_link" })}
        </span>
      </a>
      <br />
      <a
        href={"https://healthandwelfare.idaho.gov/about-dhw/privacy-and-confidentiality"}
        target="_blank"
      >
        <span className="is-underlined">
          {"Idaho Department of Health and Welfare Privacy Policy"}
        </span>
      </a>
    </div>
  );
};

export const akSearchFooter = (t: TFunction) => {
  return <div>{"Alaska: " + t("immunizations.search_records_legal", { context: "ak" })}</div>;
};
