import React, { PropsWithChildren } from "react";
import { Modal } from "./Modal";
import { useTranslation } from "react-i18next";

interface ErrorModalProps {
  title?: string | React.ReactNode;
  onCloseModal: () => void;
}

export function ErrorModal({
  title = "Error",
  children,
  onCloseModal,
}: PropsWithChildren<ErrorModalProps>) {
  const { t } = useTranslation();
  return (
    <Modal
      onCloseModal={onCloseModal}
      hasClose={true}
      title={title}
      data-testid="error-modal"
      confirm={t("generic.ok")}
      onConfirm={onCloseModal}
    >
      {children}
    </Modal>
  );
}
