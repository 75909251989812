import { atom, WritableAtom } from "jotai";
import {
  AppSettings,
  ImmunizationSearch,
  ScreeningEvent,
  ScreeningRecord,
  SearchStatus,
  UserAccount,
} from "../models/Interfaces";
import { atomWithStorage, atomFamily, loadable, RESET } from "jotai/utils";
import { EncryptedAsyncStorageDBAdapter, UnencryptedAsyncStorageDBAdapter } from "./storage";
import {
  ImmunizationEnqueuedResponseAPI,
  ImmunizationProviderConfig,
  ImmunizationRecordAPIData,
  ImmunizationRecordsAPIData,
  ImmunizationSearchAPIData,
} from "../apiClient";
import { Key } from "../database";
import { createNewRecord, createSearches } from "../hooks/getSearchesUtil";

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

export function atomWithStorageDB<T>(
  key: string,
  initVal: T,
  options?: { getOnInit?: boolean }
): WritableAtom<T | Promise<T>, [SetStateActionWithReset<T | Promise<T>>], Promise<void>> {
  return atomWithStorage<T>(key, initVal, new EncryptedAsyncStorageDBAdapter<T>(), options);
}

export function atomWithUnencryptedStorageDB<T>(
  key: string,
  initVal: T,
  options?: { getOnInit?: boolean }
): WritableAtom<T | Promise<T>, [SetStateActionWithReset<T | Promise<T>>], Promise<void>> {
  return atomWithStorage<T>(key, initVal, new UnencryptedAsyncStorageDBAdapter<T>(), options);
}

export const appSettingsAtom = atomWithUnencryptedStorageDB<AppSettings>("appSettings", {
  firebaseEmulatorConnected: false,
  email: "",
  authorized: false,
  signedInWithFirebase: false,
});

/**
 * The atom that holds the stored user account object. We don't export
 * this atom directly, as it shouldn't be used directly in the application. The
 * app can instead use the exported derived atoms built on top of this in order
 * to interact with the user data.
 */
const storedUserAccountAtom = atomWithStorageDB<UserAccount | null>(Key.UserAccount, null);
/**
 * A derived atom to get/set the user account info. This atom ensures that we
 * always delete the legacy authentication token from the user so it is not
 * stored.
 *
 * TODO: We should really have separate derived atoms for the user information
 * and the user's token pair. This would prevent us from ever accidentally
 * blowing up the user's tokens when calling something like the getUser API
 * (which doesn't return tokens, for example).
 */
export const userAtom = atom(
  (get) => get(storedUserAccountAtom),
  async (_get, set, updatedUser: UserAccount | null) => {
    if (updatedUser && "token" in updatedUser) {
      delete updatedUser.token;
    }
    return set(storedUserAccountAtom, { ...updatedUser });
  }
);

/**
 * This write-only derived user atom is helpful when you need to set the user
 * account info but have not yet unlocked the encrypted database.
 *
 * For example, if you were to use the read/write user atom on a page that does
 * not unlock the DB before rendering (eg the login page), and had already
 * loaded the application in another tab, then Jotai would try to fetch the
 * stored user info on render and fail (since the DB is not yet unlocked).
 */
export const writeOnlyUserAtom = atom(null, async (_get, set, update: UserAccount | null) => {
  await set(userAtom, update);
});

export const izProviderConfigsAtom = atomWithStorageDB<ImmunizationProviderConfig[]>(
  "providerConfigs",
  []
);
export const selectedSearchAtom = atomWithStorageDB<ImmunizationSearch | null>(
  "selectedSearch",
  null
);

export const izSearchAPIResultAtom = atomWithStorageDB<ImmunizationSearchAPIData[]>(
  "apiIzSearches",
  []
);

export const enqueuedIzSearchAtom = atomWithStorageDB<ImmunizationEnqueuedResponseAPI | null>(
  "enqueuedIzSearch",
  null
);

// Derivative atoms
export const izSearchesAtom = atom(async (get) => {
  return createSearches(await get(izSearchAPIResultAtom));
});

export const verifiedSearchesAtom = atom(async (get) => {
  const searches = await get(izSearchesAtom);
  return searches.filter((izs) => {
    return (
      izs.status !== SearchStatus.noMatch &&
      izs.status !== SearchStatus.basicMatchNoContacts &&
      izs.dateVerified !== undefined &&
      izs.dateVerified !== ""
    );
  });
});

export const patientsAtom = atom(async (get) => {
  const verifiedSearches = await get(verifiedSearchesAtom);
  return verifiedSearches.flatMap((s) => s.patients);
});

export const izRecordAPIResultAtomFamily = atomFamily((searchUid: string) =>
  atomWithStorageDB<ImmunizationRecordAPIData | null>(`apiRecords_${searchUid}`, null)
);

export const patientRecordsAtom = atom(async (get) => {
  const patients = await get(patientsAtom);
  const recordsList = [];

  for (const patient of patients) {
    const izRecordAtom = izRecordAPIResultAtomFamily(patient.searchUid); // Fetch record for each search
    const izRecord = await get(izRecordAtom);

    if (izRecord && izRecord.attributes.immunizations) {
      const { iz_search_uid, uid, iz_provider_id, iz_provider_key, patient_id, immunizations } =
        izRecord.attributes;

      const immunizationRecords = immunizations.map((immunization) =>
        createNewRecord(
          immunization,
          iz_search_uid,
          uid,
          iz_provider_id,
          iz_provider_key,
          patient_id
        )
      );

      recordsList.push(...immunizationRecords); // Flatten records
    }
  }

  return recordsList;
});

export const screeningsAPIResultAtomFamily = atomFamily((searchUid: string) =>
  atomWithStorageDB<ScreeningEvent[]>(`apiScreenings_${searchUid}`, [])
);

export const patientScreeningsAtom = atom(async (get) => {
  const patients = await get(patientsAtom);
  const screeningsList = [];

  for (const patient of patients) {
    const screeningsAtom = screeningsAPIResultAtomFamily(patient.searchUid); // Fetch screenings for each search
    const screenings: ScreeningEvent[] = await get(screeningsAtom);

    const sortedRecords = screenings.sort(
      (a, b) => new Date(b.testDate || 0).getTime() - new Date(a.testDate || 0).getTime()
    );

    let screeningRecord = {
      date: sortedRecords?.[0]?.testDate || null,
      patientId: patient.patientId,
      searchUid: patient.searchUid,
      izProviderId: patient.izProviderId,
      type: "lead",
      izProviderKey: patient.izProviderKey,
      events: sortedRecords,
      patient: patient,
    };

    screeningsList.push(screeningRecord); // Flatten screenings
  }

  return screeningsList;
});

export const currentEnqueuedSearchAtom = atom(async (get) => {
  const searches = await get(izSearchesAtom);
  const enqueuedIzSearchResult = await get(enqueuedIzSearchAtom);
  if (enqueuedIzSearchResult === null) {
    return null;
  }
  const enqueuedIzSearchUid = enqueuedIzSearchResult.uid;

  return searches.find((s) => s.uid === enqueuedIzSearchUid);
});

export const refreshingRecordsAtom = atom(async (get): Promise<ImmunizationRecordAPIData[]> => {
  const verifiedSearches = await get(verifiedSearchesAtom);
  const refreshingRecordsList = [];

  for (const search of verifiedSearches) {
    const izRecordAtom = izRecordAPIResultAtomFamily(search.uid);
    const izRecord = await get(izRecordAtom);

    if (
      izRecord &&
      (izRecord.attributes.dequeued_at === undefined || izRecord.attributes.dequeued_at === null)
    ) {
      refreshingRecordsList.push(izRecord);
    }
  }

  return refreshingRecordsList;
});

export const refreshingSearchesOrRecordsAtom = atom(async (get): Promise<boolean> => {
  const izses = await get(izSearchAPIResultAtom);
  const searchRefreshing =
    izses.find(
      (s) => s.attributes.dequeued_at === undefined || s.attributes.dequeued_at === null
    ) !== undefined;
  const recordRefreshing = (await get(refreshingRecordsAtom)).length > 0;
  return searchRefreshing || recordRefreshing;
});

// Loadable utility -- this allows us to use better loading states for async derivatives
export const loadableIzSearchesAtom = loadable(izSearchesAtom);
export const loadablePatientsAtom = loadable(patientsAtom);
