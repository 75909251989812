import React from "react";
import { useTranslation } from "react-i18next";
import { DocketSwitch } from "../form/DocketSwitch";
import { IconButton } from "../IconButton/IconButton";

interface SearchFormHeaderProps {
  showChildSearch: boolean;
  showChild: Function;
  deleteSearch: Function;
  showDelete: boolean;
}

export function SearchFormHeader({
  showChildSearch,
  showChild,
  deleteSearch,
  showDelete,
}: SearchFormHeaderProps) {
  //const location = useLocation();
  const { t } = useTranslation();

  // TODO: i18n translation
  return (
    <div className="pt-6 pr-6 pl-6">
      <div className="level-left has-text-dark has-background-white">
        <h1 className="is-text-dark is-size-4">{t("immunizations.search_start_header")}</h1>
      </div>
      <hr />
      <div className="level mb-5 mt-5">
        <div className="has-text-dark">
          <label className="label form-input-label mb-3">
            {t("immunizations.search_child_search")}
            <span className="required-tag"> ({t("system.required")})</span>
          </label>
          <div className="field is-flex is-align-items-center">
            <label className="radio-control mr-5">
              <input
                type="radio"
                name="childSearch"
                data-testid="adult-search-toggle"
                onChange={() => showChild(false)}
                checked={!showChildSearch}
              />
              &nbsp;&nbsp;{t("immunizations.search_adult_records")}
            </label>
            <label className="radio-control">
              <input
                type="radio"
                name="childSearch"
                onChange={() => showChild(true)}
                data-testid="child-search-toggle"
                checked={showChildSearch}
              />
              &nbsp;&nbsp;{t("immunizations.search_child_records")}
            </label>
          </div>
        </div>
        {showDelete && (
          <div className="level-right has-text-dark has-text-right">
            <IconButton
              dataTestId="deleteBtn"
              icon="trash"
              onClick={() => deleteSearch()}
              alt="delete"
            />
          </div>
        )}
      </div>

      <hr />
    </div>
  );
}

export default SearchFormHeader;
