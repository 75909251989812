import { TFunction } from "i18next";
import DefaultIzProvider from "./DefaultIzProvider";

export class Wyoming extends DefaultIzProvider {
  constructor(izDestinationId: string, t: TFunction) {
    super(izDestinationId, t);
    this.scenarioBImagePath = "/assets/images/large/Docket_Wyoming_scb_f.png";

    this.statusImages.redOnDue = true;
    this.statusImages.useNoRecordImage = true;

    this.reportTitles = [t("immunizations.records_history_report_wy")];
  }
}
