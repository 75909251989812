import React, { Suspense } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ReactDOM from "react-dom/client";
import "@fontsource/source-sans-pro";
import "./assets/css/docket_styles.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ErrorPage from "./ErrorPage";
import Login from "./features/onboarding/Login";
import Settings from "./features/settings/Settings";
import Discover from "./features/discover/Discover";
import { loader as homeLoader } from "./features/home/Home";
import configDb from "./database";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

// import i18n (needs to be bundled ;))
import "./i18n";
import Records from "./features/immunizations/Records";
import SearchList from "./features/immunizations/SearchList";
import Home from "./features/home/Home";
import SearchForm from "./features/immunizations/SearchForm";
import SearchResults from "./features/immunizations/SearchResults";
import AltContactsForm from "./features/immunizations/AltContactsForm";
import EnterPinForm from "./features/immunizations/EnterPinForm";
import Events from "./features/immunizations/Events";
import RequiresAuth from "./components/RequiresAuth";
import DefferedAction from "./features/sw_elements/DefferedAction";
import OfflineBanner from "./features/sw_elements/Offline";
import UserInfo from "./features/onboarding/UserInfo";
import EnterPhonePin from "./features/onboarding/EnterPhonePin";
import VerifyPhone from "./features/onboarding/VerifyPhone";
import ReportList from "./features/immunizations/ReportList";
import { STAGE } from "./globals";
import VERSION from "./version";
import SignUpTemplate from "./features/onboarding/SignUpTemplate";
import AutoLogout from "./components/timeout/AutoLogout";
import { NotFound } from "./components/NotFound";
import AccountInfo from "./features/onboarding/AccountInfo";
import { CalendarAdd } from "./features/calendar-add";
import BrandingProvider from "./branding/BrandingProvider";
import { Screening } from "./features/screening/Records";
import ScreeningEvents from "./features/screening/Events";
import { initializeI18next } from "./i18n";
import { globalStore } from "./jotai/store";
import { Provider } from "jotai";

Bugsnag.start({
  apiKey: "d521ee05a3871fc096427c05e2b6ceac",
  plugins: [new BugsnagPluginReact()],
  appVersion: VERSION,
  appType: "Browser",
  redactedKeys: [/password/i, /token/i, /name/i, /dob/i, /phone/i],
  releaseStage: STAGE,
});

configDb();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
await initializeI18next();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <NotFound />,
    action: async ({ params, request }) => {
      let formData = await request.formData();
      return {};
    },
  },
  {
    path: "/signup",
    element: <SignUpTemplate />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "userinfo",
        element: <UserInfo />,
      },
      {
        path: "accountInfo",
        element: <AccountInfo />,
      },
      {
        path: "verifyphone",
        element: <VerifyPhone />,
      },
      {
        path: "enterpin",
        element: <EnterPhonePin />,
      },
    ],
  },
  {
    path: "/add-event",
    element: <CalendarAdd />,
  },
  {
    path: "/home",
    element: (
      <RequiresAuth>
        <Home />
      </RequiresAuth>
    ),
    errorElement: <ErrorPage />,
    loader: homeLoader,
    children: [
      {
        path: "search",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <SearchList />,
          },
          {
            path: "newsearch",
            element: <SearchForm />,
          },
          {
            path: "altcontacts",
            element: <AltContactsForm />,
          },
          {
            path: "enterpin",
            element: <EnterPinForm />,
          },
          {
            path: "results",
            element: <SearchResults />,
          },
        ],
      },
      {
        path: "records",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Records />,
          },
          {
            path: "screening",
            element: <Screening />,
          },
          {
            path: "screeningevents",
            element: <ScreeningEvents />,
          },
          {
            path: "events",
            element: <Events />,
          },
          {
            path: "reports",
            element: <ReportList />,
          },
        ],
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "discover",
        element: <Discover />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// https://docs.bugsnag.com/platforms/javascript/react/
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

function Loading() {
  return <section className="section hero height-fitcontent">Loading...</section>;
}

root.render(
  <ErrorBoundary FallbackComponent={NotFound}>
    <React.StrictMode>
      <BrandingProvider>
        <Provider store={globalStore}>
          <Suspense fallback={<Loading />}>
            <DefferedAction />
            <OfflineBanner />
            <RouterProvider router={router} fallbackElement={<Loading />} />
          </Suspense>
        </Provider>
      </BrandingProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

console.log(`Version v${VERSION}`);
