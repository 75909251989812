import { TFunction } from "i18next";
import DefaultIzProvider from "./DefaultIzProvider";

export class Alaska extends DefaultIzProvider {
  constructor(izDestinationId: string, t: TFunction) {
    super(izDestinationId, t);
    this.scenarioBImagePath = "/assets/images/large/Docket_Alaska_2_scb_f.png";
    this.statusImages.overdueLabel = "due";
    this.statusImages.dueNowLabel = "due";
    this.statusImages.redOnDue = true;

    this.reportTitles = [t("immunizations.records_history_report_ak")];
  }
}
