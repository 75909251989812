import { refreshingSearchesOrRecordsAtom } from "../jotai/atoms";
import { updateSearchAtoms } from "../jotai/atomUpdater";
import { globalStore } from "../jotai/store";

/**
 * A helper to ensure that we only poll the backend for new data via a single
 * chain of API calls that exponentially back off. This is done by holding onto
 * a single Promise for the duration of the refresh chain. If the refresh method
 * is called while the promise has not yet resolved, it is simply
 * returned.
 */
class PollingHelper {
  #curRefresh: Promise<void> | null;
  readonly #maxBackoffMs: number;

  constructor() {
    this.#curRefresh = null;
    this.#maxBackoffMs = 1000 * 60; // 1 minute
  }

  refresh(): Promise<void> {
    if (!this.#curRefresh) {
      this.#curRefresh = this.internalRefresh();
    }
    return this.#curRefresh;
  }

  private async internalRefresh(): Promise<void> {
    try {
      let stillRefreshing = true;
      let attempt = 0;
      while (stillRefreshing) {
        const backoff = this.calculateBackoff(attempt);
        await new Promise((resolve) => {
          setTimeout(resolve, backoff);
        });
        /** This updates the records AND screening atoms in addition to the searches. */
        const result = await updateSearchAtoms(false);
        stillRefreshing = (await globalStore.get(refreshingSearchesOrRecordsAtom)) || !result;
        attempt += 1;
      }
    } catch (e) {
      throw e;
    } finally {
      this.#curRefresh = null;
    }
  }

  private calculateBackoff(attempt: number): number {
    // Math.pow won't overflow; it'll go to Infinity instead.
    return attempt === 0
      ? 0
      : Math.min(this.#maxBackoffMs, Math.pow(2, attempt) * 10) + Math.random() * 10;
  }
}

let refresher: null | PollingHelper = null;

/**
 * When called, the users's searches, records, and screenings (ie all PHI data)
 * will be updated asynchonously.
 */
export async function fetchRecordsUntilUpdated(forceImmediateUpdate: boolean = false) {
  if (!refresher || forceImmediateUpdate) {
    refresher = new PollingHelper();
  }
  return refresher.refresh();
}
