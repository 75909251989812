import { ImmunizationPatient, ScreeningRecord } from "../models/Interfaces";
import React from "react";
import { infoLog } from "../utils/logger";
import moment from "moment";
//import { useAppSelector } from "../hooks";
import { useTranslation } from "react-i18next";
import "./recordCard.css";
import { useNavigate } from "react-router";
import getIzProvider, { IzProvider } from "../models/providers/IzProviders";
import { izProviderConfigsAtom } from "../jotai/atoms";
import { useAtom } from "jotai";

interface RecordCardProps {
  record: ScreeningRecord;
  patient: ImmunizationPatient;
}

// Virus images
const lead = "/assets/images/Icons_Screening_Lead.png";

export default function ScreeningRecordCard({ record, patient }: RecordCardProps) {
  //  const providerConfigs = useAppSelector((store) => store.immunizations.providerConfigs);
  const [providerConfigs] = useAtom(izProviderConfigsAtom);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const emptyCard = record.events?.length < 1;
  const type = emptyCard ? "empty" : record.type.toLowerCase();
  const provider = patient.izProviderKey.toLowerCase();
  const izProvider = getIzProvider(patient.izProviderKey, t);

  const goToEvents = () => {
    if (!emptyCard) {
      navigate("../screeningevents", { state: { record: record, patient: patient } });
    }
  };

  return (
    <div onClick={() => goToEvents()} className="is-fullheight">
      <div className="columns is-gapless record-card is-justify-content-space-between">
        <div className={`${emptyCard ? "noRecordsStatus" : "recordStatus"} record-badge-bg`}></div>
        <div className={`record-badge`}>{t("screening.records_status", { context: type })}</div>

        <div className="column is-7 ml-6">
          <div
            role="paragraph"
            className="card-title pt-2 has-text-weight-semibold is-family-primary has-text-dark mt-1"
            data-testid="record-card-title"
          >
            {t("screening.lead_screening_test")}
          </div>
          {!emptyCard && (
            <div
              role="paragraph"
              className="is-size-6 is-family-primary has-text-dark "
              data-testid="record-card-description"
            >
              {t("screening.records_tested")}
              {moment.parseZone(record.date).format("L")}
            </div>
          )}
          <div
            role="paragraph"
            className={`is-size-7 has-text-weight-bold record-card-description ${
              emptyCard && "mt-1"
            }`}
            data-testid="record-card-description"
          >
            {record.events?.length || "No"} {t("screening.records_test")}
            {(record.events?.length || 0) !== 1 ? `s` : ""}
          </div>
        </div>

        <div className="column is-2 mb-4 mr-4 has-text-right is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-end">
          <img className="record-card-right-img" src={lead} alt={`Lead Image`} />
        </div>
      </div>
    </div>
  );
}
