type WithJurisdiction = {
  jurisdiction: string;
  postJurisdiction: string[];
};
type WithoutJurisdiction = {
  jurisdiction?: never;
  postJurisdiction?: never;
};

type RouteOptions = { preJurisdiction: string[]; queryParams?: Record<string, string> } & (
  | WithJurisdiction
  | WithoutJurisdiction
);

/**
 * A helper to generate a route for the V2 APIs in the Rails app, a large chunk of which include
 * jurisdiction in the route.
 *
 * @param options - The options needed to build the route.
 * @returns A V2 API route. For example:
 * "v2/immunizations/jurisdictions/nj/records/c1ae48e9-59e2-4e01-927f-755ae3a7610d"
 */
export function apiV2Route(options: RouteOptions) {
  const components = ["v2", ...options.preJurisdiction];
  if (options.jurisdiction) {
    components.push("jurisdictions", options.jurisdiction);
    components.push(...options.postJurisdiction);
  }
  const queryString = options.queryParams
    ? `?${new URLSearchParams(options.queryParams).toString()}`
    : "";
  return components.join("/").concat(queryString);
}
