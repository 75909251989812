import React from "react";
import { Spinner } from "../components/spinner/Spinner";
import { LayoutCard } from "../components/onboarding/layoutCard/LayoutCard";
import { useTranslation } from "react-i18next";

export function CalendarAdd() {
  const { t } = useTranslation();
  // Retrieve the 'link' from the URL parameters
  const link = new URLSearchParams(window.location.search).get("link");
  // If a link is provided, encode and redirect, else go to the home page

  if (!link) {
    return <></>;
  }
  React.useEffect(() => {
    if (link) {
      window.location.href = encodeURI(link);
    } else {
      window.location.href = "/";
    }
  }, [link]);

  return (
    <div className="p-6 has-text-centered is-fullwidth is-flex is-flex-direction-column is-justify-center is-fullheight">
      <LayoutCard>
        <div className="p-4">
          <h1 className="is-size-4 has-text-dark">{t("calendar.add_event")}</h1>
          <p className="has-text-dark">{t("calendar.instructions")}</p>
        </div>
      </LayoutCard>
    </div>
  );
}
