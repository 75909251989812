import { ImmunizationRecord, ScreeningRecord } from "../models/Interfaces";
import { IzProvider } from "../models/providers/IzProviders";

export const getStatusLabel = (
  izProvider: IzProvider,
  status: string,
  record: ImmunizationRecord
) => {
  if (status === "overdue") {
    return izProvider.statusImages.overdueLabel;
  } else if (status === "due_now") {
    return izProvider.statusImages.dueNowLabel;
  } else if (status === "current") {
    return izProvider.statusImages.currentLabel;
  } else if (status === "complete") {
    return izProvider.statusImages.completeLabel;
  } else if (status === "immune") {
    return izProvider.statusImages.immuneLabel;
  } else {
    if (izProvider.statusImages.useNoRecordImage && record.events?.length === 0) {
      return izProvider.statusImages.noRecordLabel;
    }
    return izProvider.statusImages.recordLabel;
  }
};

export const getStatusColor = (
  izProvider: IzProvider,
  status: string,
  record: ImmunizationRecord
) => {
  // Default is just a 'record'.
  let colorClass = "recordStatus";

  if (status === "overdue") {
    colorClass = "overdueStatus";
  } else if (status === "due_now") {
    colorClass = izProvider.statusImages.redOnDue ? "overdueStatus" : "dueOrUpdateStatus";
  } else if (status === "current") {
    colorClass = "currentStatus";
  } else if (status === "complete") {
    colorClass = "currentStatus";
  } else if (status === "immune") {
    colorClass = "immuneStatus";
  } else if (izProvider.statusImages.useNoRecordImage && record.events?.length === 0) {
    colorClass = "noRecordsStatus";
  }

  return `record-card-left-img-container is-inline-block ${colorClass}`;
};
