import { Tokens } from "./Interfaces";
import { jwtDecode } from "jwt-decode";

/**
 * An ITokenProvider gives access to underlying tokens, making it simpler to refresh them.
 */
export interface ITokenProvider {
  /**
   * Get the current tokens if they exist. The implementation may need to e.g. decrypt or refresh the token if it opts to,
   * so this returns a promise instead of a direct value.
   *
   * The initial tokens will be null. Refresh at least once to populate.
   *
   * @returns The current tokens
   */
  tokens(): Promise<Tokens | null>;

  /**
   * Refresh the tokens. This may take failure into account to back off.
   *
   * @returns The refreshed tokens, or a rejected promise if the refresh fails.
   */
  refreshTokens(): Promise<Tokens | null>;

  /**
   * Check if the token is expired. It is expected to parse to a standard JwtPayload. If it cannot be parsed, an error is thrown.
   *
   * @param token The token
   * @returns true if it's empty or expired, false otherwise
   */
  tokenExpired(token: string): boolean;
}

/**
 * Automatically refreshes tokens when they are read if they are expired.
 */
export class RefreshingTokenProvider implements ITokenProvider {
  #tokens: Tokens | null;
  #curRefresh: Promise<Tokens> | null;
  #refreshAttempt: number;
  readonly #refreshTokenFn: () => Promise<Tokens>;
  readonly #maxBackoffMs: number;

  constructor(refreshTokenFn: () => Promise<Tokens>) {
    this.#refreshTokenFn = refreshTokenFn;
    this.#curRefresh = null;
    this.#refreshAttempt = 0;
    this.#maxBackoffMs = 1000 * 60; // 1 minute
    this.#tokens = null;
  }

  async tokens(): Promise<Tokens | null> {
    if (this.tokenExpired(this.#tokens?.access || "")) {
      this.#tokens = await this.refreshTokens();
    }
    return this.#tokens;
  }

  async refreshTokens(): Promise<Tokens | null> {
    // Prevent multiple readers from racing to refresh tokens at once
    if (!this.#curRefresh) {
      this.#curRefresh = this.internalRefreshTokens();
    }
    return this.#curRefresh;
  }

  private async internalRefreshTokens(): Promise<Tokens> {
    const sleepTime = this.calculateBackoff();
    if (sleepTime > 0) {
      // We skip this block entirely on 0, since 0 is a yield.
      const sleepProm = new Promise((resolve) => {
        setTimeout(resolve, sleepTime);
      });
      await sleepProm;
    }

    try {
      const updatedTokens = await this.#refreshTokenFn();
      this.#refreshAttempt = 0;
      return updatedTokens;
    } catch (err: any) {
      this.#refreshAttempt += 1;
      throw new Error(err);
    } finally {
      this.#curRefresh = null;
    }
  }

  private calculateBackoff(): number {
    // Math.pow won't overflow; it'll go to Infinity instead.
    return this.#refreshAttempt === 0
      ? 0
      : Math.min(this.#maxBackoffMs, Math.pow(2, this.#refreshAttempt) * 10) + Math.random() * 10;
  }

  tokenExpired(token: string): boolean {
    return tokenExpired(token);
  }
}

export function tokenExpired(token: string): boolean {
  if (!token) {
    return true;
  }

  const claims = jwtDecode(token);
  if (!claims) {
    throw new Error("Invalid token; could not parse to JwtPayload");
  }

  const now = new Date();
  // iat, nbf, and exp come in the form of unix epochs in seconds. `Date` takes milliseconds.
  const expiration = new Date((claims.exp || 0) * 1000 || 0);
  const notBefore = new Date((claims.nbf || 0) * 1000 || now);
  return expiration < now || now < notBefore;
}
