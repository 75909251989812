import { BrandingConfig } from "./branding.config";

export const generateBrandingStyles = (branding: BrandingConfig) => {
  return `
      :root {
        --background-color: ${branding.siteBackground};
        --login-background-color: ${branding.loginBackgroundColor};
        --primary-color: ${branding.primaryColor};
        --navbar-background: ${branding.headerColor};
        --middle-color: ${branding.middleColor};
      }
  
      /* Global style overrides */
      html {
        background-color: var(--background-color);
      }
      .signup-image {
        background-color: var(--login-background-color);
      }
      .docket-button, .docket-button:disabled {
        background-color: var(--primary-color);
      }

      .docket-button.secondary {
        border: 0.075em solid var(--primary-color);
        background: transparent;
        color: var(--primary-color);
      }
      .docket-navbar, .navbar-dropdown, .docket-footer, .navbar-item, .navbar-link, .navbar-link:hover, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item:hover, .navbar-item:focus {
        background-color: var(--navbar-background);
      }
      .middle-column {
        background-color: var(--middle-color); 
      }

      div.login-badge {
        display: none;
      }

    `;
};
