import React, { useEffect, useRef, useState } from "react";
import { ImmunizationPatient, ImmunizationRecord, UserAccount } from "../models/Interfaces";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { infoLog } from "../utils/logger";
import { DocketAPIError, getAPIClient, reportTypeFromString } from "../apiClient";
import * as buffer from "buffer";
import db, { Key } from "../database";
import { arrayBufferToBase64, getProviderName } from "../utils/reportUtils";
import { ErrorModal } from "./modals/ErrorModal";

interface ReportCardProps {
  reportType: string;
  reportTitle: string;
  patient: ImmunizationPatient;
}

export default function ReportCard({ reportType, reportTitle, patient }: ReportCardProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const pdfRef = useRef<HTMLIFrameElement>(null);
  const [reportBase64, setReportBase64] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [available, setAvailable] = useState(false);
  const convertedReportType = reportTypeFromString(reportType);
  const [showErrorModal, setShowErrorModal] = useState<boolean | string>();
  const reportId = patient.patientId + "_" + patient.searchUid + "_" + convertedReportType;

  // check db for existing report
  useEffect(() => {
    db()
      .getItem(reportId)
      .then((value) => {
        if ((value as string) && (value as string).length > 0) {
          setReportBase64(value as string);
          setAvailable(true);
        }
      });
  }, []);

  // set reportBase64 to iframe
  useEffect(() => {
    if (reportBase64.length > 0) {
      infoLog(`reportBase64.length > 0`);
      pdfRef.current?.setAttribute("src", reportBase64);
      setAvailable(true);
    }
  }, [reportBase64]);

  const getReport = async () => {
    try {
      setIsLoading(true);
      const response = await getAPIClient().getRecordReport(
        patient.searchUid,
        convertedReportType,
        patient.izProviderKey
      );
      if (!response) {
        return;
      }
      const buffer = arrayBufferToBase64(response);
      if (buffer.length > 0) {
        // infoLog(
        //   `searchId ${patient.searchId} convertedReportType ${convertedReportType} buffer ${buffer}`
        // );
        setReportBase64("data:application/pdf;base64," + buffer);
        await db().setItem(reportId, "data:application/pdf;base64," + buffer);
        setIsLoading(false);
      }
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setAvailable(false);
        setIsLoading(false);
        setShowErrorModal(e.message);
      }
    }
  };

  const getButtonClassName = () => {
    if (isLoading) {
      return "button is-medium pr-4 pl-4 is-info mt-5 is-loading docket-button";
    } else {
      return "button is-medium pl-4 pr-4 is-info mt-5 docket-button";
    }
  };

  const getIFrameDiv = () => {
    return (
      <figure className={`image ${available && "is-16by9"}`}>
        {available ? (
          <iframe ref={pdfRef} className="has-ratio" width="640" height="360"></iframe>
        ) : (
          <iframe ref={pdfRef} className="has-ratio"></iframe>
        )}
      </figure>
    );
  };

  return (
    <>
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>{showErrorModal}</ErrorModal>
      )}
      <section className="box has-background-grey-lighter">
        <div className="level-left">
          <p className="title is-5 has-text-dark" data-testid="search-card-name">
            {reportTitle}
          </p>
        </div>
        <div className="mt-4">
          {getIFrameDiv()}
          <button onClick={() => getReport()} className={getButtonClassName()}>
            {reportBase64.length > 0 ? t("generic.refresh") : t("immunizations.reports_download")}
          </button>
        </div>
      </section>
    </>
  );
}
