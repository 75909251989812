import {
  getAPIClient,
  ImmunizationRecordAPIData,
  ImmunizationRecordsAPI,
  ImmunizationSearchAPI,
} from "../apiClient";
import {
  appSettingsAtom,
  izRecordAPIResultAtomFamily,
  izSearchAPIResultAtom,
  izSearchesAtom,
  patientsAtom,
  screeningsAPIResultAtomFamily,
  verifiedSearchesAtom,
} from "./atoms";
import { errorLog } from "../utils/logger";
import { globalStore } from "./store";
import { ImmunizationRecord, ScreeningEvent, ScreeningRecord } from "../models/Interfaces";

export async function updateSearchAtoms(refresh: boolean): Promise<boolean> {
  try {
    const searches: ImmunizationSearchAPI = await getAPIClient().getIzSearches(refresh);
    if (!searches.data) {
      return true;
    }

    await globalStore.set(izSearchAPIResultAtom, searches.data);
    // If we updated the searches we should also update the records
    return updateRecordAtoms();
  } catch (error: any) {
    // Handle error here, should be silent though
    errorLog(error, "Could not update search atoms");
    return false;
  }
}

export async function updateRecordAtoms(): Promise<boolean> {
  try {
    const patients = await globalStore.get(patientsAtom);

    if (!patients) {
      return true;
    }

    const refreshTime = new Date().getTime();

    for (const patient of patients) {
      const records: ImmunizationRecordAPIData = await getAPIClient().getIzRecord(
        patient.searchUid,
        patient.izProviderKey
      );

      if (!records) {
        console.warn(`No records found for patient: ${patient.patientId}`);
        continue;
      }

      // Use the family of atoms for records
      const izRecordAPIResultAtom = izRecordAPIResultAtomFamily(patient.searchUid);
      await globalStore.set(izRecordAPIResultAtom, records);
    }

    return await updateScreeningAtom();
  } catch (error: any) {
    errorLog(error, "Could not update record atoms");
    return false;
  }
}

export async function updateScreeningAtom(): Promise<boolean> {
  try {
    const patients = await globalStore.get(patientsAtom);

    if (!patients) {
      return true;
    }

    const refreshTime = new Date().getTime();

    for (const patient of patients) {
      const screenings: ScreeningEvent[] = await getAPIClient().getIzScreeningRecord(
        patient.searchUid,
        patient.izProviderKey
      );

      const izScreeningAPIResultAtom = screeningsAPIResultAtomFamily(patient.searchUid);
      await globalStore.set(izScreeningAPIResultAtom, screenings);
    }

    return true;
  } catch (error: any) {
    errorLog(error, "Could not update screening atom");
    return false;
  }
}

export async function clearPrefs(): Promise<void> {
  // We want to preserve the firebase emulator setting and whether or not this has NJ features
  const currentSettings = await globalStore.get(appSettingsAtom);
  await globalStore.set(appSettingsAtom, {
    ...currentSettings,
    email: "",
    authorized: false,
    signedInWithFirebase: false,
  });
}
