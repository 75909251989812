import {
  ImmunizationPatient,
  ImmunizationRecord,
  ImmunizationSearch,
  SearchStatus,
} from "../models/Interfaces";
import React from "react";
import { infoLog } from "../utils/logger";
import moment from "moment";
//import { useAppSelector } from "../hooks";
import { useTranslation } from "react-i18next";
import "./recordCard.css";
import { useNavigate } from "react-router";
import getIzProvider, { IzProvider } from "../models/providers/IzProviders";
import { getStatusColor, getStatusLabel } from "../utils/status";
import { useAtom } from "jotai";
import { izProviderConfigsAtom } from "../jotai/atoms";

interface RecordCardProps {
  index: number;
  record: ImmunizationRecord;
  patient: ImmunizationPatient;
  onSelect: Function;
}

// Virus images
const adenovirus = "/assets/images/Adenovirus.png";
const anthrax = "/assets/images/Anthrax.png";
const cholera = "/assets/images/Cholera.png";
const covid = "/assets/images/Covid19.png";
const dtap = "/assets/images/DTap.png";
const generic = "/assets/images/Default.png";
const hepa = "/assets/images/HepatitisA.png";
const hepb = "/assets/images/HepatitisB.png";
const hib = "/assets/images/HIB.png";
const hpv = "/assets/images/HumanPapilloma.png";
const influenza = "/assets/images/Influenza.png";
const japanese_encephalitis = "/assets/images/Japanese_Encephalitis.png";
const meningococcal = "/assets/images/Meningococcal.png";
const mmr = "/assets/images/MMR.png";
const monkeypox = "/assets/images/monkeypox.png";
const pneumococcal = "/assets/images/Pneumococcal.png";
const polio = "/assets/images/Polio.png";
const rabies = "/assets/images/rabies.png";
const rotavirus = "/assets/images/Rotavirus.png";
const rsv = "/assets/images/RSV.png";
const tb = "/assets/images/Tuberculosis.png";
const typhoid = "/assets/images/typhoid.png";
const varicella = "/assets/images/Varicella.png";
const yellow_fever = "/assets/images/yellow_fever.png";
const zoster = "/assets/images/zoster.png";

const update = "/assets/images/njUpdate2.png";

// TODO: i18n - We should make this part of the i18n files and just swap their paths
const overdue_es = "/assets/images/atrasada.png";
const record_es = "/assets/images/record_es.png";
const complete_es = "/assets/images/completa.png";
const current_es = "/assets/images/aldia.png";
const immune_es = "/assets/images/immuneStatusEs.png";
const dueNow_es = "/assets/images/letocaahora.png";

const qr_blue = "/assets/images/Icon_QR_Code_Blue.png";
const qr_red = "/assets/images/Icon_QR_Code_Red.png";
const qr_green = "/assets/images/Icon_QR_Code_Green.png";
const qr_yellow = "/assets/images/Icon_QR_Code_Yeller.png";

export default function RecordCard({ record, patient, index, onSelect }: RecordCardProps) {
  //  const providerConfigs = useAppSelector((store) => store.immunizations.providerConfigs);
  const [providerConfigs] = useAtom(izProviderConfigsAtom);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const status = record.status.toLowerCase();
  const provider = patient.izProviderKey.toLowerCase();
  const izProvider = getIzProvider(patient.izProviderKey, t);

  const providerEnabledQrCode = () => {
    const matchingProviderConfigs = providerConfigs.filter(
      (pc) => pc.iz_provider.destination_id.toLowerCase() === patient.izProviderKey
    );
    if (matchingProviderConfigs.length > 1) {
      throw new Error(
        `Expected to only match 1 IZ provider when determining whether or not to show a QR code, found ${matchingProviderConfigs.length}`
      );
    }

    if (matchingProviderConfigs.length === 0) {
      // We can't find this provider, so disable it by default
      return false;
    }

    const config = matchingProviderConfigs[0];
    return config.features && config.features.qr_code;
  };

  const supportsCovidQRCode = () => {
    return (
      record.vaccineType.toLowerCase().includes("covid") &&
      record.events &&
      record.events.length > 0 &&
      providerEnabledQrCode()
    );
  };

  const getShotLabel = () => {
    const shotCount = (record.events || []).length;
    return provider === "ak"
      ? `${t("immunizations.records_doses", { count: shotCount })}`
      : `${t("immunizations.records_shots", { count: shotCount })}`;
  };

  const getVirusImage = () => {
    let virus = record.vaccineType.toLowerCase();
    if (virus === "hep-a" || virus === "hep a" || virus === "hepa" || virus === "hepatitis a") {
      return hepa;
    } else if (
      virus === "hep-b" ||
      virus === "hep b" ||
      virus === "hepb" ||
      virus === "hepatitis b"
    ) {
      return hepb;
    } else if (
      virus.includes("easles") ||
      virus.includes("mumps") ||
      virus.includes("rubella") ||
      virus.includes("mmr")
    ) {
      return mmr;
    } else if (virus.toLowerCase().includes("covid")) {
      return covid;
    } else if (virus === "hib" || virus === "haemophilus influenzae b") {
      return hib;
    } else if (virus.includes("monkey") || virus.includes("small")) {
      //or smallpox
      return monkeypox;
    } else if (virus.includes("pox") || virus.includes("var")) {
      //chickenpox
      //top = -3.8
      return varicella;
    } else if (virus.includes("flu")) {
      //top = -3.5
      return influenza;
    } else if (
      virus.includes("td") ||
      virus.includes("dtp") ||
      virus.includes("tap") ||
      virus === "diphtheria, tetanus, pertussis"
    ) {
      return dtap;
    } else if (virus.includes("gococcal") || virus.includes("mening")) {
      return meningococcal;
    } else if (virus.includes("neumo")) {
      return pneumococcal;
    } else if (virus.includes("roto") || virus.includes("rota")) {
      return rotavirus;
    } else if (virus.includes("polio")) {
      return polio;
    } else if (virus.includes("COVID") || virus.includes("-19")) {
      return covid;
    } else if (virus.includes("hpv")) {
      return hpv;
    } else if (virus.includes("zoster")) {
      return zoster;
    } else if (virus.includes("typhoid")) {
      return typhoid;
    } else if (virus.includes("rabies")) {
      return rabies;
    } else if (virus.includes("encephalitis")) {
      return japanese_encephalitis;
    } else if (virus.includes("yellow")) {
      return yellow_fever;
    } else if (
      virus.toLowerCase().includes("tuberculosis") ||
      virus.toLowerCase().includes("bsv")
    ) {
      return tb;
    } else if (
      virus.toLowerCase().includes("rsv") ||
      virus.toLowerCase().includes("respiratory syncytial virus")
    ) {
      return rsv;
    } else if (virus.toLowerCase().includes("cholera")) {
      return cholera;
    } else if (virus.toLowerCase().includes("adenovirus")) {
      return adenovirus;
    } else if (virus.toLowerCase().includes("anthrax")) {
      return anthrax;
    }

    return generic;
  };

  const getQrImage = () => {
    let img = qr_blue;
    if (status === "overdue" || (status === "due_now" && izProvider.statusImages.redOnDue)) {
      img = qr_red;
    } else if (status === "due_now") {
      img = qr_yellow;
    } else if (status === "current" || status === "complete") {
      img = qr_green;
    }
    return img;
  };

  const getRecordedInText = () => {
    // This exception exists here since we have to embed the QR code
    // next to COVID, which was pushing text out too much on the mobile app.
    // This may not be needed since our UI is different in the PWA.
    let msg = t("immunizations.recorded_in", { iisAbbrev: izProvider.iisAbbrev });
    if (supportsCovidQRCode()) {
      msg = ` IN ${izProvider.iisAbbrev}`;
    }
    return msg;
  };

  const readableDate = () => {
    const date = record?.dateDue ? record?.dateDue : record?.earliestDateToGive;
    if (status === "overdue" || status === "due_now" || status === "due" || status === "current") {
      if (date) {
        // "CURRENT" vaccines may not have an upcoming due date.
        return t("immunizations.records_due_on") + moment(date).format("L");
      }
    }

    // need something here to keep height consistent, using spaces...
    return t("immunizations.records_no_due_date");
  };

  const getTitleText = (text: string) => {
    let newText = t("immunizations.records_processing");
    if (text && text !== "wait") {
      newText = text.length > 23 ? text.substring(0, 20) + "..." : text;
    }
    return newText;
  };

  const goToEvents = () => {
    if (record.events?.length! > 0) {
      navigate("events", { state: { record: record, patient: patient } });
    }
  };

  return (
    // TODO: make parent div TouchableOpacity
    <div onClick={() => goToEvents()} className="is-fullheight">
      <div className="columns is-gapless record-card">
        <div className={`${getStatusColor(izProvider, status, record)} record-badge-bg`}></div>
        <div className={`record-badge`}>
          {t("immunizations.records_status", {
            context: getStatusLabel(izProvider, status, record),
          })}
        </div>

        <div className="column is-7 ml-6">
          <div
            role="paragraph"
            className="card-title pt-2 has-text-weight-semibold is-family-primary has-text-dark mt-1"
            data-testid="record-card-title"
          >
            {getTitleText(record.vaccineType)}
          </div>
          <div
            role="paragraph"
            className="is-size-6 is-family-primary has-text-dark "
            data-testid="record-card-description"
          >
            {readableDate().length === 0 ? t("immunizations.records_no_due_date") : readableDate()}
          </div>
          <div
            role="paragraph"
            className="is-size-7 has-text-weight-bold record-card-description"
            data-testid="record-card-description"
          >
            {`${getShotLabel()} ${getRecordedInText()}`}
          </div>
        </div>
        {supportsCovidQRCode() && (
          <div
            onClick={() => goToEvents()}
            className="column is-2 mb-4 mr-4 has-text-right is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-end"
          >
            <img className="record-card-right-img" src={getQrImage()} alt="Placeholder image" />
          </div>
        )}
        <div className="column is-2 mb-4 mr-4 has-text-right is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-end">
          <img
            className="record-card-right-img"
            src={getVirusImage()}
            alt={`${record.vaccineType.toLowerCase()} Image`}
          />
        </div>
      </div>
    </div>
  );
}
