import React, { useState, forwardRef, useEffect, useCallback, useRef } from "react";
import { useMaskito } from "@maskito/react";
import { maskitoDateOptionsGenerator } from "@maskito/kit";
import "./datepicker.css";
import moment from "moment";

interface CustomDatePickerProps {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ selectedDate, setSelectedDate }) => {
  const [inputValue, setInputValue] = useState("");
  const [date, setDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const maskOptions = maskitoDateOptionsGenerator({
    mode: "mm/dd/yyyy",
    separator: "/",
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const maskRef = useMaskito({ options: maskOptions });

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = moment(selectedDate).format("MM/DD/YYYY");
      if (formattedDate !== inputValue) {
        setInputValue(formattedDate);
      }
    } else if (inputValue !== "") {
      setInputValue("");
    }
  }, [selectedDate]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setInputValue(value);

    if (value.length === 10) {
      const date = moment(value, "MM/DD/YYYY", true);
      if (date.isValid()) {
        setSelectedDate(date.toDate());
      }
    } else if (value === "") {
      setSelectedDate(null);
    }
  };

  const handleDateSelect = (selectedDate: Date) => {
    setDate(
      selectedDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    );
    setShowCalendar(false);
  };

  const SimpleCalendar = () => {
    const [currentDate, setCurrentDate] = useState(
      selectedDate ? new Date(selectedDate) : new Date()
    );

    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

    const handlePrevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const handleDateClick = (clickedDate: Date) => {
      setSelectedDate(clickedDate);
      setInputValue(moment(clickedDate).format("MM/DD/YYYY"));
      handleDateSelect(clickedDate);
    };

    const isDateSelected = (day: number) => {
      return (
        selectedDate &&
        day === selectedDate.getDate() &&
        currentDate.getMonth() === selectedDate.getMonth() &&
        currentDate.getFullYear() === selectedDate.getFullYear()
      );
    };

    return (
      <div className="has-text-dark datepicker-wrapper">
        <div className="date-labels">
          <button
            onClick={(e) => {
              e.preventDefault();
              handlePrevMonth();
            }}
            style={buttonStyle}
          >
            <span className="fa-solid fa-caret-left"></span>
          </button>
          <span>{currentDate.toLocaleDateString("en-US", { month: "long", year: "numeric" })}</span>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleNextMonth();
            }}
            style={buttonStyle}
          >
            <span className="fa-solid fa-caret-right"></span>
          </button>
        </div>

        <div className="date-grid">
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
            <div key={day} style={{ textAlign: "center", fontWeight: "bold" }}>
              {day}
            </div>
          ))}
          {Array.from({ length: firstDayOfMonth }).map((_, index) => (
            <div key={`empty-${index}`} />
          ))}
          {Array.from({ length: daysInMonth }).map((_, index) => {
            const day = index + 1;
            const isSelected = isDateSelected(day);
            return (
              <button
                key={day}
                onClick={() =>
                  handleDateClick(new Date(currentDate.getFullYear(), currentDate.getMonth(), day))
                }
                style={{
                  ...buttonStyle,
                  width: "100%",
                  backgroundColor: isSelected ? "#007bff" : "white",
                  color: isSelected ? "white" : "black",
                }}
              >
                {day}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const buttonStyle = {
    padding: "5px 10px",
    border: "1px solid #ccc",
    borderRadius: "3px",
    background: "white",
    cursor: "pointer",
  };

  return (
    <div style={{ position: "relative" }} ref={datePickerRef}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="text"
          value={inputValue}
          onInput={handleInputChange}
          ref={maskRef}
          onFocus={() => setShowCalendar(true)}
          placeholder="MM/DD/YYYY"
          className="input datepicker"
          style={{ width: "150px" }}
        />
      </div>
      {showCalendar && (
        <div className="calendar-modal">
          <SimpleCalendar />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
