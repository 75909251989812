import { TFunction } from "i18next";
import DefaultIzProvider from "./DefaultIzProvider";

export class NewJersey extends DefaultIzProvider {
  constructor(izDestinationId: string, t: TFunction) {
    super(izDestinationId, t);
    this.scenarioBImagePath = "/assets/images/large/Docket_Jersey_scb_f.png";

    this.statusImages.dueNowImagePath = "/assets/images/dueNJ.png";
    this.statusImages.noRecordImagePath = "/assets/images/noRecordNJ.png";
    this.statusImages.dueNowLabel = "due";
    this.statusImages.noRecordLabel = "no_record";
    this.statusImages.redOnDue = true;
    this.statusImages.useNoRecordImage = true;
    this.reportTitles = [
      t("immunizations.records_history_report_nj"),
      t("immunizations.records_history_report_fullnj"),
    ];
    this.screeningResultLink = "https://www.nj.gov/health/childhood-lead/";
  }
}
