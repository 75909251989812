import React from "react";
import { useLocation } from "react-router-dom";
import { ImmunizationPatient, ImmunizationRecord } from "../../models/Interfaces";
import EventCard from "../../components/EventCard";
import moment from "moment";
import getIzProvider, { IzProvider } from "../../models/providers/IzProviders";
import { ImmunizationEvent } from "../../apiClient";
import { getStatusColor, getStatusLabel } from "../../utils/status";
import "./events.css";
import { useTranslation } from "react-i18next";

interface EventLocationState {
  record: ImmunizationRecord;
  patient: ImmunizationPatient;
}

const calcDueDateText = (record: ImmunizationRecord) => {
  const { t } = useTranslation();
  if (record.dateDue && record.dateOverdue) {
    return (
      <>
        {moment(record.dateDue).format("L")} - {moment(record.dateOverdue).format("L")}
      </>
    );
  }
  if (record.dateOverdue) {
    return (
      <>
        <div className="text-bold">{t("immunizations.records_overdue_after")} </div>
        {moment(record.dateOverdue).format("L")}
      </>
    );
  }
  if (record.dateDue) {
    return (
      <>
        <div className="text-bold">{t("immunizations.records_due_on_or_after")} </div>
        {moment(record.dateDue).format("L")}
      </>
    );
  }

  return "";
};

export const EventHeaderCard = ({
  record,
  provider,
  events,
}: {
  record: ImmunizationRecord;
  provider: IzProvider;
  events: ImmunizationEvent[];
}) => {
  const dueDate = calcDueDateText(record);
  const color = getStatusColor(provider, record.status.toLowerCase(), record);
  const label = getStatusLabel(provider, record.status.toLowerCase(), record);
  return (
    <div
      className="event-card-header has-text-centered pb-2"
      style={{ backgroundColor: "#3d7bb3" }}
    >
      <h3 className="is-size-4">{record.vaccineType}</h3>
      <div
        className={`${color} status-badge pt-1 pb-1 pr-2 pl-2 has-text-weight-bold`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <p>
          {label.toUpperCase()}
          {dueDate && <p>{dueDate}</p>}
        </p>
      </div>
    </div>
  );
};

export default function Events() {
  const { state } = useLocation();
  const { record, patient } = (state as EventLocationState) || {};
  const { t } = useTranslation();

  const provider = getIzProvider(record.izProviderKey, t);
  return (
    <div className="section">
      <h2 className="has-text-dark is-size-4 has-text-centered has-text-weight-bold pb-2">
        {`${patient?.firstName || ""} ${patient?.lastName || ""}`}
      </h2>
      <br />
      <EventHeaderCard record={record} events={record.events || []} provider={provider} />
      <br />
      <ul>
        {record?.events?.map((e, index) => (
          <li className="mb-4" key={index}>
            <EventCard index={index} event={e} vaccine={record.vaccineType} />
          </li>
        ))}
      </ul>
    </div>
  );
}
