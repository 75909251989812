import React, { InputHTMLAttributes } from "react";
import "./docketSwitch.css";

interface DocketSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  checked: boolean;
  name: string;
  onChange: (e: any) => any;
}

export function DocketSwitch({ id, checked, name, onChange, ...props }: DocketSwitchProps) {
  return (
    <>
      <input
        type="checkbox"
        name={name}
        id={id}
        className="check-switch"
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <label htmlFor={id} className="switch"></label>
    </>
  );
}
