import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { errorLog, infoLog, warnLog } from "../../utils/logger";
import { DocketAPIError, getAPIClient, ImmunizationSearchPINRequest } from "../../apiClient";
import { useNavigate } from "react-router";
import { ImmunizationPatientContact, ImmunizationSearch } from "../../models/Interfaces";
import {
  formatNumber,
  setPinAllowedDate,
  getPinAllowedDate,
  setPinAttempts,
  getPinAttempts,
} from "../../utils/data";
import moment from "moment";
import { IconButton } from "../../components/IconButton/IconButton";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { izSearchAPIResultAtom, selectedSearchAtom } from "../../jotai/atoms";
import { useAtom } from "jotai";
import { fetchRecordsUntilUpdated } from "../../utils/polling";

export function AltContactsForm() {
  const [search, setSearch] = useAtom(selectedSearchAtom);
  const [_izSearchAPIResult, setIzSearchAPIResult] = useAtom(izSearchAPIResultAtom);
  //const search = useAppSelector((store) => store.immunizations.search);

  const hasContacts = () => {
    return (
      search &&
      search.patients[0] &&
      search.patients[0].contacts &&
      search.patients[0].contacts.length > 0
    );
  };

  const contacts = hasContacts() ? search!.patients[0].contacts : [];
  const groupedContacts =
    contacts?.reduce((result, contact) => {
      if (!result[contact.type]) {
        result[contact.type] = [];
      }

      result[contact.type].push(contact);
      return result;
    }, {} as { [key: string]: ImmunizationPatientContact[] }) || {};

  const [isSearchRunning, setIsSearchRunning] = useState(false);
  const [selection, setSelection] = useState("");
  const [phoneSelection, setPhoneSelection] = useState<string | undefined>();
  const [showErrorModal, setShowErrorModal] = useState<string | boolean>();
  //const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // TODO: i18n on these options. We're doing string-comparison
  const phoneOptions = ["Mobile", "Landline"];
  const { t } = useTranslation();

  const next = async () => {
    let param: ImmunizationSearchPINRequest = {};
    if (selection != null && selection.length > 0) {
      if (selection.includes("@")) {
        param["email"] = selection;
      } else if (!selection.includes("none")) {
        const parsed = phoneSelection?.includes("Mobile") ? "sms" : "voice";
        param[parsed] = selection;
      }
    }

    infoLog(`contacts param ${selection} ${JSON.stringify(param)}`);
    //await continueFromAlert(param);
    //navigate("../enterpin");
    if (param.sms || param.voice) {
      await checkForPinAttempt(param);
    } else {
      await continueFromAlert(param);
    }
  };

  const continueFromAlert = async (param: ImmunizationSearchPINRequest) => {
    infoLog(`continueFromAlert param ${JSON.stringify(param)}`);
    if (search === null) {
      errorLog(new Error("Search is somehow null!"));
      navigate("../results");
      return;
    }

    if (param.sms || param.voice || param.email) {
      setIsSearchRunning(true);
      try {
        await getAPIClient().sendImmunizationSearchPIN(search.uid, param, search.izProviderKey);
        /**
         * Do not await this since it could potentially take a long time to
         * complete and we want to navigate immediately.
         */
        fetchRecordsUntilUpdated();
        navigate("../enterpin");
      } catch (e: unknown) {
        if (e instanceof DocketAPIError) {
          setShowErrorModal(e.message);
        }
      }
    } else {
      /**
       * Do not await this since it could potentially take a long time to
       * complete and we want to navigate immediately.
       */
      fetchRecordsUntilUpdated();
      navigate("../results", { state: { isScenarioB: true } });
    }
  };

  const checkForPinAttempt = async (param: ImmunizationSearchPINRequest) => {
    const attempts = await getPinAttempts();
    const format = "YYYY-MM-DD HH:mm:ss";
    //infoLog(`checkForPinAttempt:: dateAllowed ${attempts} `)
    if (attempts >= 3) {
      const dateAllowed = await getPinAllowedDate();
      const now = moment().format(format);
      let compare = null;
      if (dateAllowed) {
        compare = moment(dateAllowed, format).isBefore(now);
      }
      //infoLog(`checkForPinAttempt:: dateAllowed ${compare} ${dateAllowed} ${now}`)
      if (dateAllowed && compare) {
        //reset and allow pin call
        await setPinAttempts(true);
        await setPinAllowedDate("");
        await setPinAttempts(false);
        continueFromAlert(param);
      } else if (!dateAllowed) {
        await setPinAllowedDate(moment().add(15, "minute").format(format));
        pinAttemptAlert();
      } else {
        pinAttemptAlert();
      }
    } else {
      //increment pin attempts
      await setPinAttempts(false);
      continueFromAlert(param);
    }
  };

  const pinAttemptAlert = () => {
    setShowErrorModal(`${t("immunizations.alt_contacts_pin_limit")}`);
  };

  const deleteSearch = async () => {
    if (search === null) {
      return;
    }

    try {
      await getAPIClient().deleteIzSearch(search.uid, search.izProviderKey);
      const updatedSearches = await getAPIClient().getIzSearches(false);
      await setIzSearchAPIResult(updatedSearches.data);
      await setSearch(null);
      navigate(-1);
    } catch (e: unknown) {
      errorLog(e as Error);
      if (e instanceof DocketAPIError) {
        setShowErrorModal(e.message);
      }
    }
  };

  const setContactNumberSelection = (number: string, selection: string) => {
    setSelection(number);
    setPhoneSelection(selection);
  };

  const getUniqueKey = (value1: string) => {
    return value1 + Math.random().toString(36).split(".")[1];
  };

  const contactCheckboxHandler = (value: any, index: number, phoneValue?: string) => {
    infoLog(`value ${value}, index ${index}`);
    if (index === 999) {
      setContactNumberSelection("-1", "-1");
      setSelection("none");
    } else {
      setContactNumberSelection(value, `${phoneValue ? phoneValue : ""}${value}`);
    }
  };

  const getContactsCheckboxSection = () => {
    return (
      <div className="mb-4">
        {Object.keys(groupedContacts).map((type) => (
          <>
            <p className="label form-input-label is-size-5 mt-4 capitalize" key={type}>
              {type}
            </p>
            {groupedContacts[type].map((contact, i) => (
              <div className="field" key={`${contact.maskedValue}-${i}`}>
                <div className="is-flex is-flex-direction-row gap-1 is-flex-wrap-wrap">
                  <label
                    className="radio-control has-text-dark mt-4"
                    data-testid={
                      contact?.maskedValue?.includes("@") ? "email-contact" : "phone-contact"
                    }
                  >
                    <input
                      type="radio"
                      checked={selection === contact.maskedValue}
                      name={"contactOption"}
                      onChange={() => contactCheckboxHandler(contact.maskedValue, i, undefined)}
                    />
                    &nbsp;&nbsp;
                    {contact?.maskedValue?.includes("@")
                      ? contact.maskedValue
                      : formatNumber(contact?.maskedValue!)}
                  </label>
                  {!contact?.maskedValue?.includes("@") && (
                    <>
                      <label className="form-input-label mt-4">this phone is: </label>
                      <label className="radio-control has-text-dark mt-4">
                        <input
                          type="radio"
                          checked={
                            selection === contact.maskedValue &&
                            phoneSelection === `${phoneOptions[0]}${contact?.maskedValue!}`
                          }
                          name={"contactSubOption"}
                          onChange={() =>
                            contactCheckboxHandler(contact.maskedValue, i, phoneOptions[0])
                          }
                        />
                        &nbsp;&nbsp;
                        {phoneOptions[0]}
                      </label>
                      <label className="radio-control has-text-dark mt-4">
                        <input
                          type="radio"
                          checked={
                            selection === contact.maskedValue &&
                            phoneSelection === `${phoneOptions[1]}${contact?.maskedValue!}`
                          }
                          name={"contactSubOption"}
                          onChange={() =>
                            contactCheckboxHandler(contact.maskedValue, i, phoneOptions[1])
                          }
                        />
                        &nbsp;&nbsp;
                        {phoneOptions[1]}
                      </label>
                    </>
                  )}
                </div>
                <br />
              </div>
            ))}
          </>
        ))}
        <div className="field mt-6" key={`none`}>
          <label className="radio-control has-text-dark">
            <input
              type="radio"
              checked={selection === "none"}
              name={"contactOption"}
              onChange={() =>
                contactCheckboxHandler(t("immunizations.alt_contacts_recognize")!, 999)
              }
            />
            &nbsp;&nbsp;{t("immunizations.alt_contacts_recognize")!}
          </label>
          <br />
        </div>
      </div>
    );
  };

  function getHeader() {
    return (
      <div className="level pl-6 pt-6 pr-6 mb-0">
        <div className="level-left">
          <h3 className="has-text-dark title">{t("immunizations.search_search_incomplete")}</h3>
        </div>
        <div className="level-right has-text-dark has-text-right">
          <IconButton
            dataTestId="deleteBtn"
            icon="trash"
            onClick={() => deleteSearch()}
            alt="delete"
          />
        </div>
      </div>
    );
  }

  const getMessage1Text = () => {
    return t("immunizations.alt_contacts_header2").split("|");
  };

  const getInfoText = () => {
    return t("immunizations.alt_contacts_pin_limit").split("|");
  };

  return (
    <>
      {getHeader()}
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>{showErrorModal}</ErrorModal>
      )}
      <div className="separator ml-6 mr-6"></div>
      <div className="section has-text-dark pt-0">
        <p>{getMessage1Text().join(" ")}</p>
        {getContactsCheckboxSection()}
        <p>{getInfoText()[0] + " " + getInfoText()[1] + " " + getInfoText()[2]}</p>
        <br />
        <button
          type="submit"
          className="button docket-button mt-5 pl-5 pr-5"
          onClick={next}
          disabled={
            !selection ||
            (!selection.includes("@") &&
              phoneSelection !== "-1" &&
              !phoneSelection?.includes("Mobile") &&
              !phoneSelection?.includes("Landline"))
          }
        >
          {t("generic.next")}
        </button>
      </div>
    </>
  );
}

export default AltContactsForm;
