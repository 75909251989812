import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./home.css";
import { Navbar } from "../../components/layout/Navbar";
import { Footer } from "../../components/layout/Footer";
import ConsentForm from "../../components/Disclaimers/ConsentForm";
import db, { Key } from "../../database";
import { UserAccount } from "../../models/Interfaces";
import localForage from "localforage";
import AutoLogout from "../../components/timeout/AutoLogout";
import { userAtom } from "../../jotai/atoms";
import { useAtom } from "jotai";
export async function loader() {
  const settings = {};
  return { settings };
}

export function Home() {
  const [showConsent, setShowConsent] = useState<boolean | undefined>();
  const [gaveConsent, setGaveConsent] = useState(false);
  const [userEmail, setUserEmail] = useState<string | undefined>();
  const [user] = useAtom(userAtom);

  useEffect(() => {
    if (!userEmail) {
      setUserEmail(user?.email);
    } else {
      const fetchConsentStatus = async () => {
        const status = await localForage.getItem(`consentAccepted_${userEmail}`);
        setGaveConsent(!!status);
        setShowConsent(!status);
      };

      fetchConsentStatus();
    }
  }, [userEmail, user]);

  const handleConsentChange = async (isChecked: boolean) => {
    await localForage.setItem(`consentAccepted_${userEmail}`, isChecked);
    setGaveConsent(isChecked);
  };

  return (
    <div className="app app-container" data-testid="home">
      <AutoLogout />
      {showConsent && (
        <ConsentForm
          handleConsentChange={handleConsentChange}
          email={userEmail as string}
          closeModal={() => setShowConsent(false)}
          gaveConsent={gaveConsent}
        />
      )}
      <main className="columns is-gapless is-clipped">
        {/*<div className="column is-one-fifth gutter" />*/}
        <div className="column">
          <Navbar />
          <div className="columns gutter">
            <div className="column is-1 ml-6 gutter"></div>
            <div className="column middle-column pt-6 pb-6">
              {/*getMainTabNavigation()*/}
              <div className="is-gapless ml-4 mr-4 has-background-white docket-main-card">
                <Outlet />
              </div>
            </div>
            <div className="column is-1 mr-6 gutter"></div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
