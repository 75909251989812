import * as yup from "yup";
import React from "react";
import { pwdCheck } from "./data";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export type Radios = {
  label: () => string;
  value: string;
  checked: boolean;
  disabled?: boolean;
};

export type ContactsCheckboxes = {
  type: string;
  value: string;
  maskedValue: string;
};

export const SearchFormSchema = yup.object().shape({
  firstName: yup.string().required("Required field!"),
  lastName: yup.string().required("Required field!"),
  // dateOfBirth: yup.string().required("Required field!"),
  // legalSex: yup.bool().oneOf([true], "Consent is required"),
  // whoAmI: yup.string().required(),
  // consent: yup.bool().oneOf([true], "Consent is required"),
  childFirstName: yup.string(),
  childLastName: yup.string(),
  childDateOfBirth: yup.string(),
  childLegalSex: yup.string(),
});

export const updatePassword = yup.object().shape({
  password: yup
    .string()
    .label("Current Password")
    .required()
    .min(8, "At least eight (8) characters, please!")
    .max(50, "No more than fifty (50) characters, please!"),
  newPassword: yup
    .string()
    .label("New Password")
    .required()
    .test(
      "check pwd",
      "Passwords must be 8+ characters and contain at least 1: uppercase, number, or special character!",
      (val) => {
        if (val == undefined) {
          return true;
        }
        return pwdCheck(val).length === 0;
      }
    ),
  confirmPassword: yup
    .string()
    .required()
    .label("Confirm password")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
});

// TODO change name to radio... these are radios
export const getHorizontalRadioSection = (
  checkboxes: Array<Radios>,
  title: string,
  checkboxHandler: Function,
  checkboxClassName: string,
  checkboxName: string,
  t: TFunction
) => {
  return (
    <div>
      <fieldset className="label form-input-label">
        {title} <span className="required-tag"> ({t("system.required")})</span>
      </fieldset>
      <div className="level is-mobile is-flex-wrap-wrap">
        {checkboxes.map((checkbox, i) => (
          <div className="level-left is-half-mobile" key={`${checkbox.label()}-${i}`}>
            <label className={`${checkboxClassName} radio-control has-text-dark`}>
              <input
                data-testid={`checkbox-${checkboxName}-${i}`}
                type="radio"
                id={checkbox.label()}
                name={checkboxName}
                checked={checkbox.checked}
                onChange={() => checkboxHandler(!checkbox.checked, i)}
              />
              &nbsp;&nbsp;{checkbox.label()}
            </label>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export const getWhoAmiRadios = (
  whoAmIValues: Array<Radios>,
  title: string,
  whoAmIHandler: Function,
  t: TFunction,
  required: boolean = true
) => {
  return (
    <div className="mb-4">
      <label className="label form-input-label mb-3">
        {title}
        {required && <span className="required-tag"> ({t("system.required")})</span>}
      </label>
      {whoAmIValues.map((who, i) => (
        <div className="field" key={`${who.label()}-${i}`}>
          <label className={`radio-control ${who.disabled && "disabled"}`}>
            <input
              data-testid={`whoami-index-${i}`}
              type="radio"
              checked={who.checked}
              name={who.label()}
              disabled={who.disabled}
              onChange={() => whoAmIHandler(!who.checked, i)}
            />
            &nbsp;&nbsp;{who.label()}
          </label>
          <br />
        </div>
      ))}
    </div>
  );
};

export const checkBoxValueMap = (dataSource: Array<Radios>, index: number) => {
  return dataSource.map((checkbox, i) => {
    if (i !== index)
      return {
        ...checkbox,
        checked: false,
      };
    if (i === index) {
      const item = {
        ...checkbox,
        checked: true,
      };
      return item;
    }
    return checkbox;
  });
};

export const getWhoAmILegacy = (t: TFunction): Radios[] => {
  return [
    {
      label: () => t("immunizations.search_who_am_i_patient"),
      value: "patient",
      checked: true,
    },
    {
      label: () => t("immunizations.search_who_am_i_legal_guardian"),
      value: "guardian",
      checked: false,
    },
    {
      label: () => t("immunizations.search_who_am_i_third_party"),
      value: "thirdParty",
      checked: false,
    },
  ];
};

export const getWhoAmIChoices = (t: TFunction) => {
  // patient, guardian, legacy
  let choices: Radios[] = [
    {
      label: () => t("immunizations.search_who_am_i_patient"),
      value: "patient",
      checked: false,
    },
    {
      label: () => t("immunizations.search_who_am_i_legal_guardian"),
      value: "guardian",
      checked: false,
    },
    {
      label: () => t("immunizations.search_who_am_i_third_party"),
      value: "thirdParty",
      checked: false,
    },
  ];
  return choices;
};

export const getWhoAmIChildChoices = (t: TFunction) => {
  // patient, guardian, legacy
  let choices: Radios[] = [
    {
      label: () => t("immunizations.search_who_am_i_patient"),
      value: "patient",
      checked: false,
      disabled: true,
    },
    {
      label: () => t("immunizations.search_who_am_i_legal_guardian"),
      value: "guardian",
      checked: true,
      disabled: false,
    },
    {
      label: () => t("immunizations.search_who_am_i_third_party"),
      value: "thirdParty",
      checked: false,
      disabled: true,
    },
  ];
  return choices;
};

export const getLocalizedLegalSexChoices = (t: TFunction) => {
  const choices: Radios[] = [
    {
      label: () => t("immunizations.search_sex_male"),
      value: "M",
      checked: false,
    },
    {
      label: () => t("immunizations.search_sex_female"),
      value: "F",
      checked: false,
    },
    {
      label: () => t("immunizations.search_sex_other"),
      value: "X",
      checked: false,
    },
    {
      label: () => t("immunizations.search_sex_unknown"),
      value: "U",
      checked: false,
    },
  ];
  return choices;
};
