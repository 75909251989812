import { FirebaseOptions } from "firebase/app";
import { STAGE } from "../../globals";

export function firebaseConfig(): FirebaseOptions {
  if (STAGE === "development") {
    return {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      authDomain: "localhost",
    };
  }

  return {
    apiKey: "AIzaSyAuaDop2srxism5qICzN0CDL7Jzr8aCCk8",
    authDomain: "docketauth.firebaseapp.com",
    databaseURL: "https://docketauth.firebaseio.com",
    projectId: "docketauth",
    storageBucket: "docketauth.appspot.com",
    messagingSenderId: "758081979991",
    appId: "1:758081979991:web:fb3518dd7575ab13a1c512",
    measurementId: "G-E801SNV9Z6",
  };
}
