import React from "react";
import "./squirrel.css";

export function Squirrel() {
  return (
    <div className="squirrel-loader">
      <div className="squirrel-wrapper">
        <img src="/assets/images/squirrel.gif" />
      </div>
    </div>
  );
}
