import { Logger } from "tslog";

const log: Logger<string> = new Logger();

export const infoLog = (message: string) => {
  log.info(`Info: ${message}`);
};

export const warnLog = (message: string) => {
  log.warn(`Warning: ${message}`);
};

export const errorLog = (error: Error, message?: string) => {
  let msg = `Error: ${error}`;
  if (message) {
    msg += ` ${message}`;
  }
  log.error(msg);
};
