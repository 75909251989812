import React, { useState, useRef, useEffect } from "react";
import Timer from "./Timer";
import { IDLE_TIMEOUT } from "../../globals";
import { IdleTimerComponent as IdleTimer, useIdleTimer } from "react-idle-timer";

const AutoLogout = () => {
  useEffect(() => {
    start();
  }, []);

  const { start, reset } = useIdleTimer({
    onIdle: () => setIsTimeout(true),
    onActive: () => {
      reset();
      setIsTimeout(false);
    },
    timeout: 1000 * 60 * IDLE_TIMEOUT,
    promptBeforeIdle: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const [isTimeout, setIsTimeout] = useState(false);

  return <div>{isTimeout && <Timer />}</div>;
};

export default AutoLogout;
